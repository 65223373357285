.contact-bg {
  background: linear-gradient(90deg, #ffffff 50%, #0e8d6c 50%);
}

.get-in-touch {
  &::after {
    display: block;
    content: "";
    width: 20%;
    position: absolute;
    background-color: #0e8d6c;
    margin: auto;
    bottom: 1%;
    left: 0%;
    border: 2px solid #0e8d6c;
  }

  position: relative;
}

.scrollY {
  overflow: hidden;
}

.scrollY:hover {
  overflow-y: scroll;
  cursor: pointer;
}

.scrollY::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border: 1px solid #525957;
  cursor: pointer;
}
.scrollY::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 5px;
  cursor: pointer;
}
.scrollY::-webkit-scrollbar-thumb {
  background: #525957;
  border-radius: 80px;
  height: 2px;
  cursor: pointer;
}
.scrollY::-webkit-scrollbar-thumb:hover {
  background: #525957;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .contact-bg {
    background: linear-gradient(
      to top,
      #0e8d6c 0%,
      #0e8d6c 62%,
      #fff 40%,
      #fff 100%
    );
  }
}
