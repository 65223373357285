.headline {
  // margin: 20px 0px;
  // padding: 60px 0px;
  margin-top: 25px;
  padding-bottom: 25px;
}

.grid-space {
  margin: 0px 3%;
}
