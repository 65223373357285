.marquee {
  /*   overflow: hidden; */
}

.marquee-content {
  display: flex;
  animation: scrolling 10s linear infinite;
}
.marquee-item {
  flex: 0 0 16vw;
  margin: 0 1vw;
}

.marquee-item img {
  display: block;
  width: 100%;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

.space-right {
  margin-right: 30px !important;
}