.card {
  box-shadow: 3px 29px 36px -9px rgba(0, 0, 0, 0.17);

  transition-property: transform;
  transition-duration: 1s;

  &:hover {
    transform: scale(1.04);
  }
}
