.grid-item {
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background: #0d1d19;
    right: 112%;
    border: 2px solid #0d1d19;
  }
}

:root {
  --line-width: 5px;
  --line-height: 3px;
  --main-line-width: calc(var(--line-width) * 2 + 2px);
  --odd-line-color: #0d1d19;
  --even-line-color: #0d1d19;
}

.timeline {
  display: grid;
  grid-template-columns: 1fr var(--main-line-width) 1fr;
  grid-auto-flow: row dense;
  text-align: center;
}

.line {
  grid-column-start: 2;
  grid-column-end: span 2;
  background: linear-gradient(currentColor, currentColor),
    linear-gradient(
      to right,
      transparent var(--line-width),
      black var(--line-width),
      black calc(var(--line-width) + 2px),
      transparent calc(var(--line-width) + 2px)
    );
  background-repeat: no-repeat;
  background-size: var(--line-width) var(--line-height),
    var(--main-line-width) 100%;
  background-position: center left;
  color: var(--odd-line-color);
  border-radius: 2px;
}

.line:nth-child(4n + 4) {
  grid-column-start: 1;
  background-position: center right;
  color: var(--even-line-color);
}

.event {
  grid-column-start: 1;
  //   border: var(--line-height) solid var(--odd-line-color);
  text-align: left;
}

.event:nth-child(4n + 3) {
  grid-column: 3;
  //   border-color: var(--even-line-color);
}

.dqsVWd {
  align-items: flex-start;
  background: transparent !important;
  border-radius: 0px !important;
  display: flex !important;
  filter: none !important;
  flex-direction: column !important;
  -moz-box-pack: start !important;
  justify-content: flex-start !important;
  line-height: 1.5em !important;
  min-height: 200px !important;
  position: relative !important;
  text-align: left !important;
  width: 98% !important;
  z-index: 0 !important;
}

.ggwgJj:not(.using-icon).active {
  background: #0d1d19 !important;
  border: 3px solid #0d1d19 !important;
}

.ggwgJj:not(.using-icon) {
  background: #0d1d19 !important;
}

.dOXaxF::after {
  background: #0d1d19 !important;
  content: "";
  display: block !important;
  height: 100% !important;
  left: 50% !important;
  position: absolute;
  width: 3px !important;
  z-index: 0 !important;
  transform: translateX(-50%) !important;
}

.dOXaxF::before {
  background: #0d1d19 !important;
  width: 3px !important;
  height: 2rem !important;
  position: absolute !important;
  content: "";
  display: block !important;
  left: 50% !important;
  top: -1rem !important;
  transform: translateY(-50%) translateX(-50%) !important;
}
