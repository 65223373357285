.watermark {
  position: fixed;
  top: 0;
  right: 0;
  z-index: -50;
  font-weight: 900;
  font-size: 200px;
  line-height: 56px;
}

.margin-top {
  margin-top: 70px;
}

.h-parent {
  height: 700px;
}

.height-100 {
  height: 100%;
}

.img-bg {
  background-image: url("../../assets/images/mobile.png");
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: left center !important;
}

.h-3-parent {
  height: 700px;
}

.img-3-bg {
  background-image: url("../../assets/images/Dashboard.png");
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: right center !important;
}

.img-45-bg {
  background-image: url("../../assets/images/dashboard2.png");
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: left center !important;
}

.h-100 {
  height: 100%;
}

.m-3-top {
  margin-top: -190px;
}

.m-top {
  margin-top: -130px;
}

.m-45-top {
  margin-top: -160px;
}

.m-5-top {
  margin-top: -90px !important;
  margin-bottom: 80px;
}

.max-width {
  max-width: 85%;
}

.tanda-image {
  max-width: 70%;
}

.h-4-parent {
  height: 750px;
}

.moveup {
  margin-top: -110px;
}

// MOBILE RESPONSIVENESS
@media screen and (max-width: 640px) {
  .text-align {
    text-align: center;
  }
  .paragraph-align {
    text-align: left;
    // width: 85%;
    margin: auto;
    font-size: 12px;
  }
  .img-bg {
    background-size: 100%;
  }
  .p-sm {
    padding: 0px 15px;
  }
  .text-size {
    font-size: 12px;
  }
  h3 {
    text-align: center;
    font-size: 18px !important;
  }

  .m-top {
    margin-top: 0px;
  }

  .pad {
    text-align: left;
    text-align: left !important;
    padding: 0px !important;
    margin: auto;
  }

  .h-parent {
    height: 850px;
  }

  .h-3-parent {
    height: 900px;
  }

  .h-4-parent {
    height: 1000px;
  }

  .img-3-bg {
    background-size: 80%;
  }

  .biometric-position {
    margin-top: -345px !important;
  }

  .img-45-bg {
    background-size: 82%;
    background-position-y: 60% !important;
  }

  .web-version {
    margin-top: -90px;
  }

  .m-46-top {
    margin-top: 380px !important;
  }

  .m-5-top {
    margin-top: -250px;
  }

  .tanda-img-top {
    margin-top: -100px;
  }
}

@media screen and (max-width: 768px) {
  .text-align {
    text-align: center;
  }

  .paragraph-align1 {
    text-align: center;
    width: 85%;
    margin: auto;
    font-size: 20px;
  }

  .padding {
    padding: 0px 20px;
  }
  .img-bg {
    background-size: 70%;
  }

  .hh {
    text-align: center;
    margin-top: 20px;
  }

  .pad {
    padding: 0px 20px;
  }

  .m-top {
    margin-top: 0px;
  }

  .pad-store {
    padding: 0px 20px;
  }

  .h-3-parent {
    height: 950px;
  }

  .img-3-bg {
    background-size: 60%;
  }

  .biometric-position {
    margin-top: -245px;
  }

  .max-width {
    max-width: 70%;
    margin: auto;
    margin-top: 56px;
    margin-bottom: 50px;
  }

  .tanda-image {
    max-width: 70%;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 40px;
  }

  .img-45-bg {
    background-size: 70%;
  }

  .h-4-parent {
    height: 800px;
  }

  .biometric-position {
    margin-top: -345px !important;
  }

  .m-6-top {
    margin-top: 90px !important;
  }
  .m-45-top {
    margin-top: 170px;
  }

 
}

@media screen and (max-width: 1180px) {
  .img-bg {
    background-size: 70%;
  }
  .m-top {
    margin-top: 0px;
  }

  .h-parent {
    height: 800px;
  }

  .justify-content {
    justify-content: flex-start;
  }
  .pad-store {
    padding: 0px 15px;
  }
  .h-3-parent {
    height: 950px;
  }

  .img-3-bg {
    background-size: 50%;
  }

  .biometric-positionlk {
    margin-top: -180px;
  }

  .max-width {
    max-width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tanda-image {
    max-width: 70%;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 40px;
  }

  .img-4-bg {
    background-size: 70%;
  }

  .h-4-parent {
    height: 800px;
  }

  .biometric-position {
    margin-top: -345px !important;
  }

  .m-6-top {
    margin-top: 90px !important;
  }

  .m-45-top {
    margin-top: 170px;
  }
}
