.button-footer {
  padding: 10px 20px;

  animation-name: btn-animation;
  animation-duration: 3s;
  animation-delay: 3s;
  animation-fill-mode: forwards;

  transition-property: transform;
  transition-duration: 1s;

  &:hover {
    transform: scale(1.1);
  }
}

@keyframes btn-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
