.tanda {
  &::after {
    display: block;
    content: "";
    width: 10%;
    position: absolute;
    background-color: red;
    bottom: 0;
    left: 0;
    bottom: -8;
    border: 2px solid #e83e1c;
  }

  position: relative;
}
