@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Inter_Black;
    src: url("./assets/fonts/Inter/static/Inter-Black.ttf");
  }
  @font-face {
    font-family: Inter_Bold;
    src: url("./assets/fonts/Inter/static/Inter-Bold.ttf");
  }
  @font-face {
    font-family: Inter_ExtraBold;
    src: url("./assets/fonts/Inter/static/Inter-ExtraBold.ttf");
  }
  @font-face {
    font-family: Inter_ExtraLight;
    src: url("./assets/fonts/Inter/static/Inter-ExtraLight.ttf");
  }
  @font-face {
    font-family: Inter_Light;
    src: url("./assets/fonts/Inter/static/Inter-Light.ttf");
  }
  @font-face {
    font-family: Inter_Medium;
    src: url("./assets/fonts/Inter/static/Inter-Medium.ttf");
  }
  @font-face {
    font-family: Inter_Regular;
    src: url("./assets/fonts/Inter/static/Inter-Regular.ttf");
  }
  @font-face {
    font-family: Inter_SemiBold;
    src: url("./assets/fonts/Inter/static/Inter-SemiBold.ttf");
  }
  @font-face {
    font-family: Inter_Thin;
    src: url("./assets/fonts/Inter/static/Inter-Thin.ttf");
  }
}
