@media screen and (max-width: 640px) {
  .slide-from-top {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-in;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-in {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }

  .slide-from-bottom {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-out;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-out {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }
}

@media screen and (max-width: 768px) {
  .slide-from-top {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-in;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-in {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }

  .slide-from-bottom {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-out;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-out {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }
}

@media screen and (max-width: 1180px) {
  .slide-from-top {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-in;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-in {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }

  .slide-from-bottom {
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    animation-name: slide-out;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  @keyframes slide-out {
    0% {
      margin-top: -200%;
    }
    100% {
      margin-top: 2%;
    }
  }
}
