.under-line {
  &::after {
    display: block;
    content: "";
    width: 5%;
    position: absolute;
    background-color: red;
    margin: auto;
    bottom: 0;
    left: 48%;
    bottom: -8;
    border: 2px solid #e83e1c;
  }

  position: relative;
}

@media screen and (max-width: 640px) {
  .w-small {
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
  }
}
