.hero {
  h2 {
    width: 80%;
    position: relative;
  }
}

.span2 {
  &::after {
    display: block;
    content: url("../../assets/images/Vector\ 1.png");
    width: 62%;
    position: absolute;
    bottom: 0;
    right: 15px;
    top: 104px;
    transform: scale(0.55);
  }
}

.button {
  padding: 17px 34px;

  animation-name: btn-animation;
  animation-duration: 3s;
  animation-delay: 3s;
  animation-fill-mode: forwards;

  transition-property: transform;
  transition-duration: 1s;

  &:hover {
    transform: scale(1.1);
  }
}

@keyframes btn-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animated {
  animation: shake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.text-inherit {
  text-align: inherit;
}
.p {
  width: 80%;
}

@media screen and (max-width: 640px) {
  .hero-text {
    margin: auto;

    h2 {
      width: 100%;
    }

    p {
      text-align: justify;
      width: 100%;
    }

    .p {
      text-align: justify;
      width: 100%;
    }
  }

  .button {
    padding: 10px 20px;
  }

  .animated {
    animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}
