.blog-banner {
  background-image: url(../../assets/images/blog_banner.png);
}

.blog-title {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slug-banner {
  background-image: url(../../assets/images/slug_banner.png);
}